.breaking-news-headline {
  display: block;
  position: absolute;
  font-family: arial;
  font-size: 20px;
  margin-top: 9px;
  color: white;
  margin-left: 100%;
  text-decoration: wavy;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  justify-content: space-between;
  align-items: flex-start;
}

.breaking-news--close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  background: #333;
  z-index: 25;
}

.breaking-news-link {
  display: block;
  top: 0;
  position: absolute;
  width: 95%;
  height: 100%;
  z-index: 20;
  cursor: pointer;
}

.breaking-news-title {
  background-color: #e1271b;
  color: white;
  display: inline-block;
  height: 42px;
  width: 130px;
  font-family: arial;
  font-size: smaller;
  position: absolute;
  top: 0px;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 11px;
  padding-left: 10px;
  z-index: 3;
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    top: 0;
    left: -12px;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid #e1271b;
    border-bottom: 0px solid #e1271b;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    right: -12px;
    top: 0;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    border-top: 0px solid #e1271b;
    border-bottom: 0px solid #e1271b;
  }
}

#breaking-news-colour {
  height: 45px;
  width: 100%;
  background-color: #0f2453
}

#breaking-news-container {
  height: 50px;
  width: 100%;
  overflow: hidden;
  display: flex;
  position: absolute;
  margin-top: 150%;
  margin-left: 100%;
  margin-right: 100%;
  margin-bottom: 100%;

  &:before {
    content: "";
    width: 100%;
    height: 0px;
    background-color: #0f2453;
    position: absolute;
    display: flex;
    z-index: 2;
  }
}

.animated {
  -webkit-animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0.2s;
  -moz-animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
}

.delay-animated {
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0.4s;
  -moz-animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-column-gap: 1rem;
  // transform: rotate(-50deg);
  // -webkit-transform: rotate(-50deg);
}

.scroll-animated {
  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 3s;
  -moz-animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-animated2 {
  -webkit-animation-duration: 0s;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0s;
  -moz-animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-animated3 {
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 5s;
  -moz-animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-delay: 0.5s;
  animation-delay: 3s;
}

.fadein {
  -webkit-animation-name: fadein;
  -moz-animation-name: fadein;
  -o-animation-name: fadein;
  animation-name: fadein;
}

@-webkit-keyframes fadein {
  from {
    margin-left: 1000px;
    opacity: 0;
  }

  to {}
}

@-moz-keyframes fadein {
  from {
    margin-left: 1000px;
    opacity: 0;
  }

  to {}
}

.slidein {
  -webkit-animation-name: slidein;
  -moz-animation-name: slidein;
  -o-animation-name: slidein;
  animation-name: slidein;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  20% {
    left: 50%;
  }
  100% {
    left: -100%;
  }
}

.marquee {
  animation: marquee 30s linear infinite;  // Slower speed
  -webkit-animation-duration: 30s;  // Adjusted duration
  -moz-animation-duration: 30s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes slidein {
  from {
    margin-left: 800px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    opacity: 1;
  }
}

@-moz-keyframes slidein {
  from {
    margin-left: 800px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    opacity: 1;

  }
}

.slideup {
  -webkit-animation-name: slideup;
  -moz-animation-name: slideup;
  -o-animation-name: slideup;
  animation-name: slideup;
}

@-webkit-keyframes slideup {
  from {
    margin-top: 30px;
  }

  to {
    margin-top: 0;
  }
}

@-moz-keyframes slideup {
  from {
    margin-top: 30px
  }

  to {
    margin-top: 0;

  }
}