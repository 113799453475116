.ticker {
  display: flex;
  background-color: #0f2453;
  width: 115%;
  overflow: hidden;
  flex: 0 0 115%;

  --gap: 0.5rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.ticker-list {
  display: flex;
  flex: 10 10 115%;
  width: 115%;

  flex-shrink: 0;
  display: flex;
  /* justify-content: space-around; */
  min-width: 115%;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.ticker:hover .ticker-list {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.tickerItem {
  border-left: 1px solid transparent;
  display: table-cell;
  position: relative;
  vertical-align: top;
  color: #fff;
  width: 115%;
  top: 2px;
  bottom: 10px;
}

.tickerItem:hover {
  background-color: none;
}

.tickerItem:after {
  background: none;
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: -1px;
  width: 1px;
}