.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.ReactModal__Content {
}

.modal-close-btn,
.modal-close-btn:hover,
.modal-close-btn:active,
.modal-close-btn:focus {
  position: fixed;
  top: 0px;
  right: 0px;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #333 !important;
  opacity: 0.7;
  transition: 0.1s opacity ease-in-out;
}

.modal-close-btn:hover {
  opacity: 1;
}

.modal-close-btn:active {
  opacity: 0.9;
}

.confirm-claim-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  position: absolute;
  background: #fff;
  padding: 35px 35px 20px 35px;
  border: 0 !important;
  outline: 0 !important;
  border-radius: 8px;

  table > tbody > tr > td {
    font-size: 1em;
    padding: 0.25em 0;
  }

  .confirm-modal-label {
    font-weight: bold;
  }
  .confirm-modal-buttons {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    button {
      flex-grow: 1;
      padding: 15px 15px !important;
    }
    button:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 22, 72, 0.65) !important;
}

.cancel-claim-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 500px;
  position: absolute;
  background: #fff;
  padding: 0 0 20px 0;
  border: 0 !important;
  outline: 0 !important;
  border-radius: 8px;
  width: 500px;
  max-height: 60%;

  .modal-header {
    background: $brandPrimary;
    color: white;
    border-radius: 8px 8px 0 0;
    padding: 20px 35px;
    margin: 0;
    h5 {
      margin: 0;
      font-size: 1.75em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .close-btn {
        padding: 0px !important;
        border: none;

        &:hover {
          background-color: $brandSecondary;
        }
      }
    }
  }

  .modal-body {
    padding: 20px 35px;
    max-height: 425px;
    // overflow: auto;
  }

  .cancel-modal-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      min-height: 50px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-icon {
        display: flex !important;
        align-items: center;
        margin-right: 5px;
      }
    }
    button:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.files-modal {
  @extend .cancel-claim-modal;
  .modal-header {
    background: $brandSecondary;
  }
}
